import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

const tag_title = "Not found - 샘랩";
const tag_description = "에러 페이지 입니다.";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};
const headingStyles = {
  fontSize:'2rem',
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
      <title>{tag_title}</title>
        <meta name="title" content={tag_title} />
        <meta name="description" content={tag_description} />
        <meta property="og:title" content={tag_title} />
        <meta property="og:description" content={tag_description} />
        <mata property="og:url" content="https://samlab.co.kr/404" />
        <meta property="twitter:title" content={tag_title} />
        <meta property="twitter:description" content={tag_description} />
        <meta property="twitter:url" content="https://samlab.co.kr/404" />
      </Helmet>
      <span style={headingStyles}>페이지를 찾을 수 없습니다.</span>
      <p style={paragraphStyles}>
        죄송합니다{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        에러가 발생했습니다.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">홈으로 가기</Link>.
      </p>
    </main>
  );
};

export default NotFoundPage;
